import React from 'react'
import Layout from '../../components/layout'
import { Link } from 'gatsby'

const SuchIsLove = () => (
  <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">i am so, so into you babe, let me count the ways</span>
        </div>
      </div>
    </section>
    <section className="section song">
      <div className="container song is-fluid">
        <div className="columns">
          <div className="column is-half art such_is_love"></div>
          <div className="column is-one-quarter lyrics">
            <p>
              She flows into my valleys <br/>
              dances ’cross my plains <br/>
              climbs my highest mountains <br/>
              and brings me gentle rains
            </p>

            <p>
              I provide a base for her <br/>
              enrich her wondrous soul <br/>
              whatever she may need from me <br/>
              I will find and bring it whole
            </p>

            <p>
              Such is love &hellip; the thrill of combination <br/>
              Such is love &hellip; creating a brand new world<br/>
              Such is love &hellip; joyous eternal friendship <br/>
              A vow... to guide and shelter all... all within our fold
            </p>

            <p>
              Deep inside I’m warm now <br/>
              on my surface rivers run <br/>
              Time to wake the travelers <br/>
              to thrive beneath the sun
            </p>

            <p>
              And yet my peace is broken <br/>
              No! This I dare not dwell upon <br/>
              I know she’ll never leave me, <br/>
              but what if I can’t hold on?
            </p>

            <p>
              Such is love &hellip; the agony and ecstasy <br/>
              Such is love &hellip; to build with spirit bold <br/>
              Such is love &hellip; I wonder what became of me? <br/>
              A vow... to guide and shelter all... and all within our fold
            </p>
          </div>

          <div className="column is-one-quarter lyrics">
            <p>
              Lover draw me close to thee <br/>
              Against the black and cold of night <br/>
              We hold the key to life within us <br/>
              Fail not your precious hold on me
            </p>
          </div>
        </div>
      </div>
  </section>
  </Layout>
)

export default SuchIsLove
